
	.i-copyright {
    margin: 15px 0 60px 0 !important;
    display: flex;
    justify-content: center;
	}
  .ivu-global-footer-links {
    display: flex;
    flex-direction: column;

    a {
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
