
/deep/ .ivu-input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }
}
