
.flowTabs {
  //   padding: 15px;
  display: block;
  background: #ffffff;
  margin-bottom: 15px;

  .process-btn-area {
    display: flex;
    flex-wrap: wrap;
  }
}
.toolbar {
  box-shadow: 0 0 20px 0 rgba(0, 91, 172, .1);
  background-color: #fff;
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;

  &-inner {
    display: flex;
    padding: 7.5px 15px;
    align-items: center;
    justify-content: space-between;

    > * {
      width: 100%;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    /deep/ .ivu-select-dropdown {
      width: auto !important;
    }
  }
}
