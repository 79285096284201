
/deep/ .JFDrawer {

  .ivu-drawer-body {
    display: flex;
    padding: 0;
    flex-direction: column;
  }
  .ivu-card-head,
  .ivu-card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .ivu-card-extra {
    right: 0;
  }
}
.drawer {

  &-body {
    flex: 1;
    padding: 16px;
    overflow: auto;
  }
  &-footer {
    border-top: 1px solid #e8eaec;
    box-shadow: 0 0 20px 0 rgba(0, 91, 172, .1);
    background-color: #fff;
    position: relative;
    z-index: 10;

    &-inner {
      display: flex;
      padding: 7.5px 15px;
      justify-content: flex-end;
    }
  }
}
