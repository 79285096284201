
// 安全区
.safe-area-inset-bottom {
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.safe-area-inset-top {
  padding-top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

// 文本颜色
.ivu-text-primary {
  color: #2d8cf0;
}

.ivu-text-success {
  color: #19be6b;
}

.ivu-text-warning {
  color: #ff9900;
}

.ivu-text-error {
  color: #ed4014;
}

.ivu-text-info {
  color: #2db7f5;
}
