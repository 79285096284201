
.cell {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &-title {
    flex: 0 0 70px;
  }
  &-desc {
    flex: 1;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
}
.nextAuditPersonName {
  margin-right: 15px;
}
/deep/ .ivu-radio-group {
  display: block;
}
/deep/ .ivu-radio-wrapper {
  padding: 16px 0;
  display: block;
  border-bottom: 1px solid #e8eaec !important;
  position: relative;

  .ivu-card {
    padding-left: 30px;

    &-body {
      padding: 0;
    }
  }
}
/deep/ .ivu-radio {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 17px;
}
